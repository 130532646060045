import { NodeFunction } from '../../permissions/models/node-function';
import { UserProvider } from '../../permissions/models/user-provider';

export const getNodes: NodeFunction = (user: UserProvider, feature, bouncer, and, or, not, config, additional) => [
	{
		nameKey: 'text.mp',
		url: '/workflow.do?from=menu&to=marketingplaner',
		id: 'marketingplaner',
		permission: and(feature.has('marketingplaner'), user.has('marketingplaner'), user.isSubuser())
	},
	{
		treeName: 'booking',
		name: 'bookingTree',
		url: '',
		id: 'bookingTree',
		permission: user.isSubuser()
	},
	{
		nameKey: 'image.nav.my.wbk.alt',
		url: '/workflow.do?from=menu&to=prefs',
		id: 'prefs',
		children: [
			{
				nameKey: 'image.metanav.preferences.alt',
				url: '/workflow.do?from=menu&to=preferences',
				id: 'preferences',
				parameters: {
					'showInUserNav': true,
				}
			},
			{
				nameKey: 'kpi.dashboard.only.label',
				id: 'kpiDashboard',
				url: 'kpi-dashboard',
				query: { resetBreadcrumbs: true },
				permission: and(
					feature.has('showKpiDashboard'),
					not(
						feature.has('socialmedia')))
			},
			{
				nameKey: 'kpi.some-dashboard.label',
				id: 'socialMediaDashboard',
				url: 'social-media/social-media-dashboard',
				query: { resetBreadcrumbs: true },
				permission:
					and(
						feature.has('socialmedia'),
						not(
							feature.has('showKpiDashboard')))
			},
			{
				nameKey: 'kpi.overview-dashboards.label',
				id: 'dashboardsOverview',
				url: 'kpi-dashboard/overview',
				query: { resetBreadcrumbs: true },
				permission:
					and(
						feature.has('socialmedia'),
						and(
							feature.has('showKpiDashboard')))
			},
			{
				nameKey: 'lokalegooglewerbung.uebersicht.label',
				id: 'lokaleGoogleWerbung',
				url: 'lokale-google-werbung',
				permission:
					and(user.hasCountryCode('DE'),
						or(
							feature.has('showLokaleGoogleWerbung'),
							and(
								feature.has('showLokaleGoogleWerbungByGroup'),
								or(
									user.isAttribute('accessGroup', 'MV-Administrator'),
									user.hasGroup('LokaleGoogleWerbungPilotPartner')
								)
							)
						)
					),
				parameters: {
					'showInUserNav': true,
				}
			},
			{
				treeName: 'newsletterxslt',
				name: 'newsletterTree',
				url: '',
				id: 'newsletterTree',
				permission:
					and(
						feature.has('newsletterxslt'),
						not(
							and(
								feature.has('newsletterTemplates'),
								not(
									user.has('hasPrivateNewsletters')
								)
							)
						),
						bouncer.check('allowedNewsletterAccess')
					)
			},
			{
				treeName: 'userpicture',
				name: 'userpictureTree',
				url: '',
				id: 'userpictureTree',
				permission: feature.has('userpicture')
			},
			{
				nameKey: 'image.nav.wkz.alt',
				url: 'workflow.do?from=menu&to=requestsUser',
				id: 'requestsUser',
				permission: feature.has('budget')
			},
			{
				nameKey: 'image.nav.archive.alt',
				url: 'workflow.do?from=menu&to=archive',
				id: 'archive',
				permission: feature.has('archive'),
				parameters: {
					'showInUserNav': true
				}
			},
			{
				nameKey: 'text.select.prefs.abos',
				url: 'configuration/aboverwaltung',
				query: { resetBreadcrumbs: true },
				id: 'aboverwaltung',
				permission: feature.has('aboVerwaltung'),
				parameters: {
					'showInUserNav': true,
				},
			},
			{
				nameKey: 'text.koprauserprices',
				url: 'workflow.do?from=menu&to=kopra',
				id: 'kopra',
				permission: feature.has('kopra')
			},
			{
				nameKey: 'text.werbewiderspruch',
				url: 'workflow.do?from=menu&to=werbewiderspruch-download',
				id: 'werbewiderspruch-download',
				permission:
					and(
						feature.has('werbewiderspruchManagement'),
						not(
							and(
								feature.has('werbewiderspruchBlockAdvertisingKonzern'),
								user.is('d_status_konzern', '1')
							)
						)
					)
			}
		]
	},
	{
		nameKey: 'image.nav.admin.alt',
		url: 'workflow.do?from=menu&to=admin',
		id: 'admin',
		permission: user.any(['service', 'konfiguration', 'lektorat', 'showReport', 'serviceWkz', 'exportGutschriftenWkz', 'adminWebTmGrid']),
		children: [
			{
				nameKey: 'image.nav.service.alt',
				url: 'workflow.do?from=menu&to=service',
				id: 'service',
				permission: and(feature.has('service'), user.has('service'))
			},
			{
				nameKey: 'image.nav.konfiguration.alt',
				url: 'workflow.do?from=menu&to=konfiguration',
				id: 'konfiguration',
				permission: and(feature.has('konfiguration'), user.has('konfiguration'))
			},
			{
				nameKey: 'text.lektorat',
				url: 'workflow.do?from=menu&to=lektorat',
				id: 'lektorat',
				permission: and(feature.has('lektorat'), user.has('lektorat'))
			},
			{
				treeName: 'report',
				name: 'reportTree',
				url: '',
				id: 'reportTree',
				permission: and(feature.has('report'), user.has('showReport'))
			},
			{
				nameKey: 'image.nav.wkz.admin.alt',
				url: 'workflow.do?from=menu&to=budgetAdmin',
				id: 'budgetAdmin',
				permission: and(feature.has('budget'), user.any(['serviceWkz', 'exportGutschriftenWkz']))
			},
			{
				treeName: 'webtm',
				name: 'webtmTree',
				url: '',
				id: 'webtmTree',
				permission: and(feature.has('webtm'), user.has('adminWebTmGrid'))
			}
		]
	}
];
