import { Routes } from '@angular/router';
import { customerRoutes } from './customer.route';
import { ErrorFallbackComponent } from './error-fallback/error-fallback.component';
import { loggedInGuard } from './permissions/logged-in.guard';
import { createFeatureAccessGuard } from './permissions/module-access.guard';
import { socialMediaAccessGuard } from './social-media/social-media-access.guard';
import { StrutsComponent } from './struts/struts.component';
import { strutsGuard } from './struts/struts.guard';

export const routes: Routes = [
	...customerRoutes,
	{
		path: '',
		redirectTo: '/start.do',
		pathMatch: 'full',
	},
	{
		path: 'angularlogin.do',
		redirectTo: '/start.do',
		pathMatch: 'full',
	},
	{
		path: 'ssologin.do',
		redirectTo: '/angularlogin.do',
		pathMatch: 'full',
	},
	{
		path: 'login.do',
		redirectTo: '/angularlogin.do',
		pathMatch: 'full',
	},
	{ path: 'error', redirectTo: '/strutsreturn.do?forwardTo=error-default-message' },
	{ path: 'error-fallback', component: ErrorFallbackComponent },
	{
		path: 'marketingplaner',
		loadChildren: () => import('./marketingplaner/marketingplaner.routes').then(m => m.MARKETINGPLANER_ROUTES),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'beverage-verwaltung',
		loadChildren: () => import('./beverage-verwaltung/beverage-verwaltung.module').then(m => m.BeverageVerwaltungModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'configuration',
		loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'preferences',
		loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'social-media',
		loadChildren: () => import('./social-media/social-media.module').then(m => m.SocialMediaModule),
		canActivate: [
			loggedInGuard,
			socialMediaAccessGuard,
		],
	},
	{
		path: 'kpi-dashboard',
		loadChildren: () => import('./kpi-dashboard/kpi-dashboard.module').then(m => m.KpiDashboardModule),
		canActivate: [
			loggedInGuard,
			createFeatureAccessGuard('showKpiDashboard'),
		],
	},
	{
		path: 'plakat-favoriten',
		loadChildren: () => import('./plakat-favoriten/plakat-favoriten.module').then(m => m.PlakatFavoritenModule),
		canActivate: [
			loggedInGuard,
			createFeatureAccessGuard('plakatStandortFavoritenGeokoordinaten'),
		],
	},
	{
		path: 'lokale-google-werbung',
		loadChildren: () => import('./lokale-google-werbung/lokale-google-werbung-host.module').then(m => m.LokaleGoogleWerbungHostModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'service/job',
		loadChildren: () => import('./service/job/service-job.module').then(m => m.ServiceJobModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'unique-id-vergleich',
		loadChildren: () => import('./service/unique-id-vergleich/unique-id-vergleich.module').then(m => m.UniqueIdVergleichModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'sepa-transport-jobs-administration',
		loadChildren: () => import('./service/sepa-transport-jobs-administration/sepa-transport-jobs-administration.module').then(m => m.SepaTransportJobsAdministrationModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'image-compare',
		loadChildren: () => import('./service/image-compare/image-compare.module').then(m => m.ImageCompareModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'edit-homepage/:id',
		loadChildren: () => import('./service/edit-homepage/edit-homepage.module').then(m => m.EditHomepageModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: 'startseiten-slider',
		loadChildren: () => import('./startseiten-slider/startseiten-slider.module').then(m => m.StartseitenSliderModule),
		canActivate: [
			loggedInGuard,
			createFeatureAccessGuard('sliderPictureUpload'),
		],
	},
	{
		path: 'auth/protocol/openid-connect',
		loadChildren: () => import('./sso-provider/sso-provider.module').then(m => m.SsoProviderModule),
		canActivate: [
			loggedInGuard,
		],
	},
	{
		path: '**',
		component: StrutsComponent,
		canActivate: [strutsGuard],
	},
];
