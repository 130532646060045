<ng-container *ngIf="('debug' | userPermission | async) && debug()">
	<div style="padding-top: 80px;"> DB: {{ debug().database }}</div>
	<div> Version: {{ debug().version }}</div>
	<div> SysInfo: {{ debug().system }}</div>
	<div> Kubernetes: {{ debug().kubernetes }}</div>
	<div> LokaleGoogleWerbung: {{ debug().lokale_google_werbung }}</div>
	<div> Loomar-Bestellungen: {{ debug().loomar_bestellungen }}</div>
	<div> Loomar-News: {{ debug().loomar_news }}</div>
	<div> Loomar-Bestellformular: {{ debug().loomar_bestellformular }}</div>
	<div *ngIf="debug().newsletterGroupName">
		{{ 'frame.footer.newsletterid' | msgKey:[{ '%id%': debug().newsletterGroupName }] }}
	</div>
</ng-container>